export const title = 'Przygotowanie do druku DTP';
export const description = 'Jako drukarnia internetowa profesjonalnie wykonamy ulotki, wizytówki czy banery. Kompleksowo przygotujemy twój projekt do druku. Zapraszamy do zapoznania się z całością oferty';
export const mainHeader = 'Projekty graficzne - wizytówki, logo, banery';

export const content = [
  {
    article: `<p class="thin">Ludzie pierwsze wrażenie mogą zrobić tylko raz i z materiałami reklamowymi nie jest inaczej. Sytuacja staje się wręcz
    bardziej skomplikowana. Kreacja musi przyciągnąć i wzbudzić przyjazne odczucie w ułamku sekundy. Inaczej stanie się
    niezauważona i pominięta. Dlatego też projektowanie wizytówek, banerów, plakatów, kalendarza, logo, ulotki powinno
    uwzględniać każdy najmniejszy detal.</p>`,
  },
  {
    article: `## Usługi poligraficzne DTP, czyli <span class="orange">przygotowanie do druku</span>
    \n Wykonujemy koncepcje graficzne wszystkich materiałów reklamowych, od drobnej wizytówki po gigantyczne banery, od
    broszury informacyjnej po kilkuset stronne katalogi produktowe. Naszą specjalnością jest również projektowanie plakatów,
    ulotek, kalendarzy. Nie ma znaczenia czy jest to jeden projekt na roll-up czy spójna kreacja na pokaźny event. Kreatywne
    pomysły przekierowujemy do każdej zleconej pracy w ramach usług poligraficznych.
    \n Oprócz składów DTP na drukowane materiały reklamowe specjalizujemy się również w kreowaniu identyfikacji wizualnej
    dla marek, instytucji, firm oraz produktów. Przygotowujemy i realizujemy, wobec tego projekty banerów, logo grafik
    wyświetlanych w Internecie oraz tworzymy spójne kreacje na social media i serwisy internetowe.
    `,
  },
  {
    article: `## Dlaczego warto skorzystać z naszych usług poligraficznych DTP?
    \n Zlecając przygotowanie dowolnych materiałów, możesz je później u nas wydrukować mając pewność pełnej zgodności z
    wymogami technicznymi i ustawień maszyn. Posiadamy wieloletnie doświadczenie, jeśli chodzi o projektowanie logo,
    banerów, wizytówek, kalendarzy, ulotek i plakatów. Co ważne, graficy posiadający odpowiednie umiejętności oraz wiedzę
    stale podążają za aktualnymi trendami wyznaczanymi w grafice i ilustracji reklamowej.
    `,
  },
];
