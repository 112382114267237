/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* Components */
import {
  SEO, Container, Section, Article, Heading, PriceList,
} from '../components/components';

/* Messages */
import * as messages from '../messages/projekty-graficzne.messages';

const ProjektyGraficzne = () => (
  <>
    <SEO title={messages.title} description={messages.description} />
    <Container subPage>
      <Heading headingLevel="h1" title={messages.mainHeader} highlightedSecondWord centered />
      <Section>
        {messages.content.map(({ header, article }) => (
          <Article key={article}>
            {header && <Heading headingLevel="h2" title={header} centered />}
            <Markdown rehypePlugins={[rehypeRaw]} children={article} />
          </Article>
        ))}
      </Section>
      <Section id="cennik">
        <PriceList
          customTitle="Cennik projektów graficznych"
          range="Ceny - PROJEKTY GRAFICZNE!A1:B30"
          wide
        />
      </Section>
    </Container>
  </>
);

export default ProjektyGraficzne;
